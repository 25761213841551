$(document).ready(function(e) {
	/* mob_menu */
	$('.m_ctrl').on('click', function() {
		$(this).toggleClass('active');
		$('.mob_menu_wr').slideToggle(300)
	});

	$('.mm_ddown_item').on('click', function() {
		$(this).toggleClass('open');
		$(this).siblings('.mm_ddown').slideToggle(300)
	});

	/* hidden form */
	$('.c_wr2').on('click', '.btn', function() {
		$('.c_wr2').slideToggle(300);
		$('.c_wr3').slideToggle(300);
	});

	/*accordion*/
	$('.b_info9').on('click', '.head', function() {
		$(this).toggleClass('open');
		$(this).siblings('.descr').slideToggle(300);
	});

	/*Слайдер*/
	$('.slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		appendArrows: '.slider_nav'
	});

	/*Карусель*/
	$('.carousel').slick({
		slidesToShow: 10,
		slidesToScroll: 1,
		rows: 3,
		infinite: false,
		responsive: [
			{
				breakpoint: 1235,
				settings: {
					slidesToShow: 8
				}
			},
			{
				breakpoint: 981,
				settings: {
					slidesToShow: 6
				}
			},
			{
				breakpoint: 751,
				settings: {
					slidesToShow: 5
				}
			},
			{
				breakpoint: 601,
				settings: {
					slidesToShow: 4
				}
			},
			{
				breakpoint: 361,
				settings: {
					slidesToShow: 3
				}
			}
		]
	});

	$('.carousel1').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		vertical: true,
		verticalSwiping: true,
		initialSlide: 10,
		asNavFor: '.carousel2',
		arrows: false
	});

	var elements = $('.carousel2 > div').size();

	$('.carousel2').slick({
		slidesToShow: 7,
		slidesToScroll: 1,
		infinite: false,
		vertical: true,
		verticalSwiping: true,
		initialSlide: elements-1,
		swipe: false,
		focusOnSelect: true,
		asNavFor: '.carousel1',
		appendArrows: '.c_wr7_arrows',
		responsive: [
			{
				breakpoint: 731,
				settings: {
					slidesToShow: 5,
					initialSlide: 10
				}
			}
		]
	});

	$('.carousel3').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: 0,
		responsive: [
			{
				breakpoint: 1081,
				settings: {
					slidesToShow: 3,
					centerPadding: '150px'
				}
			},
			{
				breakpoint: 851,
				settings: {
					slidesToShow: 1,
					centerPadding: '220px'
				}
			},
			{
				breakpoint: 751,
				settings: {
					slidesToShow: 1,
					centerPadding: '200px'
				}
			},
			{
				breakpoint: 641,
				settings: {
					slidesToShow: 1,
					centerPadding: '150px'
				}
			},
			{
				breakpoint: 551,
				settings: {
					slidesToShow: 1,
					centerPadding: '130px'
				}
			},
			{
				breakpoint: 481,
				settings: {
					slidesToShow: 1,
					centerPadding: '100px'
				}
			},
			{
				breakpoint: 401,
				settings: {
					slidesToShow: 1,
					centerPadding: '75px'
				}
			}
		]
	});

	$('.carousel4').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		asNavFor: '.carousel5'
	});

	$('.carousel5').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		infinite: false,
		// swipe: false,
		asNavFor: '.carousel4',
		focusOnSelect: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 851,
				settings: {
					slidesToShow: 4
				}
			}
		]
	});

	/*fancybox*/
	$("[data-fancybox]").fancybox({
		btnTpl: {
			close: '<button type="button" data-fancybox-close class="fancybox-button fancybox-button--close"><i class="icon-close"></i></button>'
		},
		smallBtn: false,
		idleTime: false
	});

	$("[data-fancybox_a]").fancybox({
		btnTpl: {
			smallBtn: '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small"><i class="icon-close"></i></button>'
		},
		idleTime: false
	});

	$("[data-fancybox_c]").fancybox({
		btnTpl: {
			smallBtn: '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small"><i class="icon-close"></i></button>'
		},
		idleTime: false
	});

	$('[data-fancybox="gallery_a"]').fancybox({
		baseClass: "fancybox_gallery_a",
		baseTpl:
			'<div class="fancybox-container" role="dialog" tabindex="-1">' +
			'<div class="fancybox-bg"></div>' +
			'<div class="fancybox-inner">' +
			'<div class="fancybox-stage"></div>' +
			"</div>" +
			"</div>",
		buttons : [
			'close'
		],
		btnTpl: {
			smallBtn: '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small"><i class="icon-close"></i></button>',
			arrowLeft:
				'<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
				'<i class="icon-arrow-b-left"></i>' +
				"</button>",
			arrowRight:
				'<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
				'<i class="icon-arrow-b-right"></i>' +
				"</button>"
		},
		smallBtn: true,
		idleTime: false,
		loop: true,
		toolbar: false,
		animationEffect: "fade",
		afterLoad: function(instance, slide) {
			$('<div class="fancybox-navigation"><button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left"><i class="icon-arrow-b-left"></i></button><button data-fancybox-next class="fancybox-button fancybox-button--arrow_right"><i class="icon-arrow-b-right"></i></button></div>').appendTo(slide.$content);
		}
	});

	/* $(".gallery_b").fancybox({
		btnTpl: {
			close: '<button type="button" data-fancybox-close class="fancybox-button fancybox-button--close"><i class="icon-close"></i></button>'
		},
		toolbar: false,
		idleTime: false,
		afterLoad: function() {
			$('.carousel6').slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: false
			});
		}
	}); */

	$(".btn_popup_d").fancybox({
		btnTpl: {
			smallBtn: '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small"><i class="icon-close"></i></button>'
		},
		idleTime: false
	});

	/*tel_mask*/
	$(".tel_mask").mask("+373 999 999 99 99", {placeholder: ""});

	/*Форма*/
	$('input, select').styler({
		selectPlaceholder: '',
		selectVisibleOptions: 7
	});
	$('.switch input').styler('destroy');

	autosize(document.querySelectorAll('textarea'));

	/*100vh mobile fix*/
	// слушаем событие resize
	window.addEventListener('resize', function() {
		// получаем текущее значение высоты
		var vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', vh+'px');
	});

	$(document).on('submit', '#contact_request_form_two', function(event){
		event.preventDefault();
		self = $(this);
		var data = self.serialize();
		var url = self.attr("action");
		self.find(".btn").addClass("btn-prld");
		self.find(".btn .text").hide();
		$.ajax({
			type: "POST", url: url, data: data, dataType: 'json',
			success: function(res) {
				if (res.error === true) self.find("a#button-popup_b").trigger("click")
				else self.find("a#button-popup_c").trigger("click");
				window.location.hash = "!";
				self.find("input[type=text], input[type=email], input[type=tel]").val("");
				self.find(".btn").removeClass("btn-prld");
				self.find(".btn .text").show();
			},
			error: function(res){ console.log(res); }
		});
	});

	$(document).on('submit', '#contact_request_form', function(event){
		$("#popup_d .fancybox-close-small").trigger("click");
		event.preventDefault();
		self = $(this);
		var data = self.serialize();
		var url = self.attr("action");
		self.find(".btn").addClass("btn-prld");
		self.find(".btn .text").hide();

        $.ajax({
			type: "POST", url: url, data: data, dataType: 'json',
			success: function(res) {
				if (res.error == true) self.find("a#button-popup_b").trigger("click")
				else self.find("a#button-popup_a").trigger("click");
				self.find("input[type=checkbox]").prop( "checked", false );
				self.find("input[type=text], input[type=tel], textarea").val("");
				self.find(".valid-msg").addClass("hide");
				self.find(".btn").removeClass("btn-prld");
				self.find(".btn .text").show();
			},
			error: function(res){ console.log(res); }
		});
    });

});